import Vue from 'vue'
import axios from 'axios'

const store = {
  debug: false,
  state: {
    cart: null,
    csrf: {
      tokenName: '',
      tokenValue: ''
    },
    faq: null,
    locale: null,
    showMenuOverlay: false,
    showSideCart: false,
    showIntroVideo: false,
    isPrivate: true,
    sameBillingAddress: true,
    shippingAddress: null,
    billingAddress: null,
    registerUser: false,
    isPartner: null,
    email: '',
    filters: {},
    accessories: [],
    defaults: {},
    selectedOptions: {},
    selectedSamples: {},
    samplesInCart: 0,
    sampleProductsInCart: 0,
    cartUpdated: false,
    additionInfo: {},
    translates: null,
    productsCount: 0,
    configurations: {}
  },
  setSamplesInCart () {
    const cart = this.state.cart
    if (!cart || !cart.hasOwnProperty('lineItems')) {
      this.state.samplesInCart = 0
      this.state.sampleProductsInCart = 0

      return
    }

    const lineItemsSamples = Object.values(cart.lineItems).filter(item => item.type === 'colorSamples')
    if (lineItemsSamples.length) {
      this.state.samplesInCart = lineItemsSamples
        .map(item => item.qty)
        .reduce((acc, currQty) => acc + currQty, 0)
    }

    let samplePackCount = 0
    const lineItemsSamplePack = Object.values(cart.lineItems).filter(item => item.type === 'colorSamplePack')
    if (lineItemsSamplePack.length) {
      samplePackCount = lineItemsSamplePack
        .map(item => item.qty)
        .reduce((acc, currQty) => acc + currQty, 0)
    }

    this.state.sampleProductsInCart = samplePackCount + this.state.samplesInCart
  },
  loadData (data) {
    for (const key in data) {
      Vue.set(this.state, key, data[key])
    }
  },
  getSelectedLengthVal () {
    if (this.state.selectedOptions.v_len) {
      if (!this.state.selectedOptions.v_len.vMaxLen) {
        return this.state.selectedOptions.v_len.vMinLen
      } else {
        if (this.state.additionInfo.v_len) {
          return this.state.additionInfo.v_len
        }
      }
    }
    return 0
  },
  getFixedLengths () {
    if (this.state.filters && this.state.filters.hasOwnProperty('v_len')) {
      return this.state.filters.v_len.filter(item => item.vMaxLen === null)
    }
    return []
  },
  setFilters (filters) {
    this.state.filters = filters
  },
  setAccessories (accessories) {
    this.state.accessories = accessories
  },
  setCart (cart) {
    Vue.set(this.state, 'cart', cart)
  },
  setProductsCount (count = 0) {
    Vue.set(this.state, 'productsCount', count)
  },
  getProductsCount () {
    return this.state.productsCount
  },
  getCartItems () {
    return this.state.cart.lineItems
  },
  isCartContainsShelves () {
    const items = this.state.cart.lineItems
    const foundedShelves = Object.values(items).find((item) => item.type === 'main' && item.SKU !== '108')

    return typeof foundedShelves !== typeof undefined
  },
  getLastShelve () {
    const cartItemsIndexes = Object.keys(this.getCartItems()).reverse()
    let lastItem = null

    for (const item of cartItemsIndexes) {
      if (typeof this.getCartItems()[item].options.Depth !== 'undefined') {
        lastItem = this.getCartItems()[item]
        break
      }
    }

    return lastItem
  },
  getComputedAccessories () {
    const necessaryAccessoriesSKUs = ['A201']
    const optionalAccessoriesSKUs = ['A204.22', 'A204.29', 'A202.22', 'A202.29']
    const lastItem = this.getLastShelve()

    const filteredAccessories = []
    if (lastItem) {
      const lastItemDepth = parseFloat(lastItem.options.Depth.split(',')[0])
      this.getAccessories().forEach((accessory) => {
        if (necessaryAccessoriesSKUs.includes(accessory.sku)) {
          filteredAccessories.push(accessory)
        }
        if (optionalAccessoriesSKUs.includes(accessory.sku)) {
          if (lastItemDepth === accessory.vMinDepth || (lastItemDepth === 22 && accessory.vMinDepth === null)) {
            filteredAccessories.push(accessory)
          }
        }
      })
    }

    return filteredAccessories
  },
  isMobile: () => window.innerWidth < 920,
  getAccessories () {
    return this.state.accessories
  },
  getAdvertedAccessories () {
    if (this.getSelectedOption('v_depth')) {
      return this.state.accessories
        .filter(acc => acc.vdepthsCategory.find(d => d.vDepth === this.getSelectedOption('v_depth').vDepth))
    }
    return []
  },
  setDefaults (config) {
    this.state.defaults = config
  },
  setSelectedOption (key, value) {
    if (value === null) {
      Vue.delete(this.state.selectedOptions, key)
    } else {
      Vue.set(this.state.selectedOptions, key, value)
    }
  },
  setSelectedSample (key, value) {
    if (value === null) {
      Vue.delete(this.state.selectedSamples, key)
    } else {
      Vue.set(this.state.selectedSamples, key, value)
    }
  },
  getSelectedOption (key) {
    if (!key) return this.state.selectedOptions
    return this.state.selectedOptions[key]
  },
  getSelectedSample (key) {
    if (!key) return false
    return this.state.selectedSamples[key]
  },
  setAdditionInfo (key, value) {
    if (value === null) {
      Vue.delete(this.state.additionInfo, key)
    } else {
      Vue.set(this.state.additionInfo, key, value)
    }
  },
  getAdditionInfo (key) {
    if (!key) return this.state.selectedOptions
    return this.state.additionInfo[key]
  },
  setFilter (key, value) {
    Vue.set(this.state.filter, key, value)
  },
  setCartAction (object) {
    if (this.debug) console.log('setCartAction triggered with', object)
    this.state.cart = object.cart ? object.cart : {}
  },
  setPartner (object) {
    this.state.isPartner = object.isPartner ? object.isPartner : false
  },
  setFaqAction (object) {
    if (this.debug) console.log('setFaqAction triggered with', object)
    this.state.faq = object
  },
  setShippingAddressAction (id) {
    if (this.debug) console.log('setShippingAddressAction triggered with', id)
    this.state.shippingAddress = id
  },
  setBillingAddressAction (id) {
    if (this.debug) console.log('setBillingAddressAction triggered with', id)
    this.state.billingAddress = id
  },
  setCsrf () {
    const CSRF_ENDPOINT = '/actions/blitz/csrf/token'
    const _this = this

    async function getCsrf (_this) {
      try {
        const response = await axios.get(CSRF_ENDPOINT)
        _this.state.csrf.tokenName = 'CRAFT_CSRF_TOKEN'
        _this.state.csrf.tokenValue = response.data
      } catch (error) {
        console.error('CSRF fetch failed', error)
      }
    }

    getCsrf(_this)
  },
  toggleMenuOverlayAction () {
    if (this.debug) console.log('toggleMenuOverlayAction triggered')
    this.state.showSideCart = false
    this.state.showMenuOverlay = !this.state.showMenuOverlay
    const body = document.body
    body.classList.toggle('noscroll', this.state.showMenuOverlay)
  },
  toggleSideCartAction () {
    if (this.debug) console.log('toggleSideCartAction triggered')
    this.state.showMenuOverlay = false
    this.state.showSideCart = !this.state.showSideCart
  },
  toggleIntroVideoAction () {
    if (this.debug) console.log('toggleIntroVideoAction triggered')
    this.state.showIntroVideo = !this.state.showIntroVideo
  },
  _translates (key) {
    if (this.state.translates === null) return key
    if (this.state.translates.hasOwnProperty(key)) return this.state.translates[key]
    if (this.debug) { console.warn(`no translation found for "${key}"`) }
    return key
  }
}

export default store
